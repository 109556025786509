import React from 'react';

import Home1 from './section/Home1';

import Home2 from './section/Home2';
import Home3 from './section/Home3';
import Home4 from './section/Home4';
import Home5 from './section/Home5';
import Home6 from './section/Home6';
import Home7 from './section/Home7';
import Video from './section/Video';
import Chart from './../chart/Chart';

const Home = () => {
    return (
        <>
            <Home1/>
            <Video/>
            <Home2/>
            <Home3/>
            <Home4/>
            <Home5/>
            <Chart/>
            <Home6/>
            <Home7/>
        </>

    )
}

export default Home;

