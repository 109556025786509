import React, { useEffect } from 'react';
import QnA1 from "./section/QnA1";

const QnA = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div>
                <QnA1/>
            </div>
        </>
    )
}

export default QnA;
