import React, {useEffect} from 'react';
import history from '../../../images/history/history.png'

const IntroCoreValue1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (


        <div className="m-auto">
            <h2 className="text-[#242429] font-[600] font-bold
                                   lg:text-[64px] lg:mt-[200px] lg:ml-[100px]
                                   md:text-[56px] md:mt-[150px] md:ml-[70px]
                                   sm:text-[48px] sm:mt-[100px] sm:ml-[40px] ">
                연혁</h2>
            <div className="flex lg:flex-row w-full justify-items-center">
                <div className="flex justify-center lg:my-10 w-full h-full">
                    <img className={"  min-w-[90dvw]"} src={history} alt="history"/>
                </div>
            </div>

        </div>

    );
}

export default IntroCoreValue1;
