import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import w0 from "../../../images/pub2/우진.png";
import w1 from "../../../images/pub2/고려출판물류.png";
import w2 from "../../../images/pub2/북마을.jpg";
import w3 from "../../../images/pub2/북스라인.png";
import w4 from "../../../images/pub2/북앤북.jpg";
import w5 from "../../../images/pub2/북존로지스틱스.png";
import w6 from "../../../images/pub2/비상피앤엘.jpg";
import w7 from "../../../images/pub2/행복한수레.png";
import w8 from "../../../images/pub2/신화.png";
import w9 from "../../../images/pub2/알림북.jpg";
import w10 from "../../../images/pub2/오케이북.jpg";
import w11 from "../../../images/pub2/지평.jpg";
import w12 from "../../../images/pub2/책과일터.jpg";
import w13 from "../../../images/pub2/코업북.png";
import w14 from "../../../images/pub2/한국도서유통.png";
import w15 from "../../../images/pub2/해피데이.jpg";
import w16 from "../../../images/pub2/너른북.jpg";
import w17 from "../../../images/pub2/스카이북.jpg";
import w18 from "../../../images/pub2/해피라인.png";
import w19 from "../../../images/pub2/진성사.png";
import w20 from "../../../images/pub2/파말마.jpg";
import w21 from "../../../images/pub2/이지스토리지.png";
import w22 from "../../../images/pub2/희망라인.png";
import w23 from "../../../images/pub2/천리마.jpg";

import w24 from "../../../images/pub2/손수레.png";
import w25 from "../../../images/pub2/하마물류.png";
import w26 from "../../../images/pub2/코러스미디어.png";
import w27 from "../../../images/pub2/컴파크.png";
import w28 from "../../../images/pub2/차경물류.png";
import w29 from "../../../images/pub2/상원도서물류.png";
import w30 from "../../../images/pub2/북패스.png";
import w31 from "../../../images/pub2/북마스터.png";
import w32 from "../../../images/pub2/북112.png";

import w33 from "../../../images/pub2/신영북스.png";
import w34 from "../../../images/pub2/런닝북.png";
import w35 from "../../../images/pub2/북센.png";
import w36 from "../../../images/pub2/북스케어.jpg";
import w37 from "../../../images/pub2/에듀킨더.png";
import w38 from "../../../images/pub2/용남사.jpg";
import w39 from "../../../images/pub2/위드북.jpg";
import w40 from "../../../images/pub2/하나북시스템.jpg";
import w41 from "../../../images/pub2/한강라인.jpg";
import w42 from "../../../images/pub2/한강물류.jpg";
import w43 from "../../../images/pub2/라임북.jpg";


const images6 = [ w0,  w24,  w2, w3,  w25, w5, w6, w7,  w26, w9,  w10, w27, w12, w13, w10, w28, w15, w16, w17, w18, w29, w20 ];
const images7 = [ w21, w22, w30, w1,  w4, w8, w31, w14, w19, w23, w11, w32, w33, w34, w35, w36, w37, w38, w39, w40, w41, w42 ,w43 ];

const images8 = [ w0,   w1,  w2, w3,  w4,  w5,  w6,  w7,  w8,  w9,  w10, w11, w12, w13, w14, w15,
                         w16, w17, w18, w19, w20, w21, w22, w23, w33, w34, w35, w36, w37, w38, w39, w40, w41, w42, w43 ,
                         w24, w25, w26, w27, w28, w29, w30, w31, w32, ];

const Home6 = () => {
    const [showAllImages, setShowAllImages] = useState(true);
    const [setSectionHeight] = useState('auto');

    const toggleShowAllImages = () => {
        setShowAllImages(prevState => !prevState);
        if (!showAllImages) {
            setSectionHeight('200vh');
        } else {
            setSectionHeight('auto');
        }
    };


    const setting1 = {
        dots: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0.0001,
        cssEase: 'linear',
        variableWidth: true,
        pauseOnHover: false,
    };

    const setting2 = {
        rtl:true,
        dots: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0.0001,
        cssEase: 'linear',
        variableWidth: true,
        pauseOnHover: false,
    };

    return (
        <div className="text-white
                                lg:min-h-[740px] lg:max-h-[740px]
                                md:min-h-[700px] md:max-h-[700px]
                                sm:min-h-[300px] sm:min-w-[320px]">
                    <div className="lg:mt-[120px] lg:mb-[108px]
                                    md:mt-[100px] md:mb-[80px]
                                    sm:mt-[80px]">
                        <div className="w-full flex flex-col lg:justify-around relative">
                                <h2 className=" text-black text-center text-[#333333] font-bold
                                               lg:text-[44px] lg:leading-[58px] lg:font-[700] lg:text-[#333333]
                                               md:text-[34px] md:leading-[52px] md:font-[700] md:text-[#333333]
                                               sm:text-[14px] sm:leading-[28px] sm:font-[700] sm:text-[#333333]
                                               ">
                                    출판사를 위해, 서점을 위해, <br/>
                                    날개와 함께 협력하는 최고의 물류대행사들입니다.
                                </h2>
                        </div>
                    </div>
                    <div className="sm:hidden">
                        <Slider {...setting1}>
                            {images6.map((image, index) => (
                                <div key={index} className="lg:h-[110px] lg:mb-[60px]
                                                            md:h-[80px]  md:mb-[100px]">
                                    <img src={image} alt={`Slide ${index}`} className=" border-gray-300
                                                                                       lg:mr-[150px] lg:w-[185px]
                                                                                       md:mr-[120px] md:w-[130px] md:mb-[50px] "/>
                                </div>
                            ))}
                        </Slider>
                        <Slider {...setting2}>
                            {images7.map((image, index) => (
                                <div key={index} className="lg:h-[110px] lg:mb-[8px]
                                                            md:h-[80px]  ">
                                    <img src={image} alt={`Slide ${index}`} className=" border-gray-300
                                                                                       lg:mr-[150px]  lg:w-[185px]
                                                                                       md:mr-[120px]  md:w-[130px] md:mb-[30px] "/>
                                </div>
                            ))}
                        </Slider>
                    </div>

            <div className="sm:pb-[20px] border-0 lg:hidden md:hidden">
                <div className="flex flex-wrap h-auto ">
                    {(showAllImages ? images8 : images6).map((image, index) => (
                        <div key={index} className="lg:w-1/3 md:w-1/3 sm:w-1/3 p-[4px]">
                            <img src={image} alt={`Slide ${index}`} className="w-[80%] m-auto  border-gray-100 rounded-3xl  " />
                        </div>
                    ))}
                </div>
                <div className="text-center">
                    <button onClick={toggleShowAllImages} className=" bg-[#c8c8c8] white font-[700] text-[18px]  mt-2  border-[1px] border-gray-200 rounded-xl h-[47px] w-[170px]">
                        {showAllImages ? '닫기' : '더 보기'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Home6;
