import React, { useState, useEffect } from 'react';
import Footer from '../../../components/Footer';
import { useDocTitle } from '../../../components/CustomHook';
import axios from 'axios';
import Notiflix from 'notiflix';

const Contact = () => {
    useDocTitle('The Best Book Logistics :: 날개:: ')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState([])

    const clearErrors = () => {
        setErrors([])
    }

    const clearInput = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submitBtn').disabled = true;
        document.getElementById('submitBtn').innerHTML = 'Loading...';
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)

        axios({
            method: "post",
            url: process.env.REACT_APP_CONTACT_API,
            data: fData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(function (response) {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'send message';
                clearInput()
                //handle success
                Notiflix.Report.success(
                    'Success',
                    response.data.message,
                    'Okay',
                );
            })
            .catch(function (error) {
                document.getElementById('submitBtn').disabled = false;
                document.getElementById('submitBtn').innerHTML = 'send message';
                //handle error
                const {response} = error;
                if (response.status === 500) {
                    Notiflix.Report.failure(
                        'An error occurred',
                        response.data.message,
                        'Okay',
                    );
                }
                if (response.data.errors !== null) {
                    setErrors(response.data.errors)
                }

            });
    }

    useEffect(() => {
        // Scroll to the top when the component mounts or refreshes
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="absolute lg:mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-gray-500 rounded-2xl z-10 right-0">
                <div className="flex flex-col text-white">

                    <div className="flex my-4 w-2/3 lg:w-3/4">
                        <div className="flex flex-col">
                            <i className="fas fa-map-marker-alt pt-2 pr-2"/>
                        </div>
                        <div className="flex flex-col">
                            <h2 className="text-3xl">Office Address</h2>
                            <p className="text-2xl text-gray-200">경기도 파주시 파주읍 부곡리 7-12 KR</p>
                        </div>
                    </div>

                    <div className="flex my-4 w-2/3 lg:w-1/2">
                        <div className="flex flex-col">
                            <i className="fas fa-phone-alt pt-2 pr-2"/>
                        </div>

                        <div className="flex flex-col">
                            <h2 className="text-3xl">Call Us</h2>
                            <p className="text-2xl text-gray-200">Tel: 031-940-1300</p>

                            <div className='mt-5'>
                                <h2 className="text-3xl">Send an E-mail</h2>
                                <p className="text-2xl text-gray-200"> webmaster@nalgae.co.kr</p>
                            </div>

                        </div>
                    </div>

                    <div className="flex my-4 w-2/3 lg:w-1/2">
                        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer"
                           className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 className='fill-current font-black hover:animate-pulse'>
                                <path
                                    d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/enlighteneering-inc-" target="_blank"
                           rel="noreferrer"
                           className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 className='fill-current font-black hover:animate-pulse'>
                                <circle cx="4.983" cy="5.009" r="2.188"></circle>
                                <path
                                    d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div id='contact' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 ">

                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <form onSubmit={sendEmail}>
                        <div
                            className="w-full bg-white p-8 my-4 md:px-12 lg:w-full lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">

                            <div className="flex">
                                <h2 className="mt-52 text-5xl text-black font-bold">
                                    고객문의
                                </h2>
                            </div>
                            <div className="flex flex-col justify-center items-center mt-6 m-0 p-0 w-full h-full"
                                 id='QA'>

                                <hr className="mt-32 mb-10 w-3/4 border-b border-black"/>

                                <div className="flex mr-32 justify-center w-full">
                                    <div className="flex flex-col justify-center items-start w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">문의분야</label>
                                    </div>
                                    <div className=" flex flex-col justify-start items-start w-4/5 ">
                                        <select id="category"
                                                className="p-2 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500">
                                            <option value="option1">납품</option>
                                            <option value="option2">계약 문의</option>
                                            <option value="option3">배송 문의</option>
                                            <option value="option4">환불/교환 문의</option>
                                            <option value="option4">기타</option>
                                            <option value="option4">재고 문의</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="mt-6 mr-32 flex justify-center w-full">
                                    <div className="flex flex-col justify-center items-start w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">업체명</label>
                                    </div>

                                    <div className="flex flex-col justify-center items-start w-4/5">
                                        <label htmlFor="company" className="text-lg text-black font-bold mb-2"></label>
                                        <input type="text" id="company"
                                               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-2/4"
                                               placeholder="업체명을 입력해 주세요."/>
                                    </div>
                                </div>

                                <div className="mt-6 mr-32 flex justify-center w-full">
                                    <div className="flex flex-col justify-center items-start  w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">담당자</label>
                                    </div>

                                    <div className="flex flex-col justify-center items-start w-4/5">
                                        <input type="text" id="company"
                                               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-2/4"
                                               placeholder="담당자를 입력해 주세요."/>
                                    </div>
                                </div>

                                <div className="mt-6 mr-32 flex justify-center w-full">
                                    <div className="flex flex-col justify-center items-start  w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">이메일</label>
                                    </div>

                                    <div className="flex flex-col justify-center items-start w-4/5">
                                        <input type="text" id="company"
                                               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-2/4"
                                               placeholder="이메일을 입력해 주세요."/>
                                    </div>
                                </div>

                                <div className="mt-6 mr-32 flex justify-center w-full">
                                    <div className="flex flex-col justify-center items-start w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">전화번호</label>
                                    </div>

                                    <div className="flex flex-col justify-center items-start w-4/5">
                                        <input type="text" id="company"
                                               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-2/4"
                                               placeholder="전화번호를 입력해 주세요."/>
                                    </div>
                                </div>
                                <div className="mt-6 mr-32 flex justify-center w-full">
                                    <div className="flex flex-col justify-center items-start w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">문의제목</label>
                                    </div>

                                    <div className="flex flex-col justify-center items-start w-4/5">
                                        <input type="text" id="company"
                                               className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-full"
                                               placeholder="문의제목을 입력해 주세요."/>
                                    </div>
                                </div>
                                <div className="mt-6 mr-32 flex justify-center w-full">
                                    <div className="flex flex-col justify-start items-start w-1/12">
                                        <label htmlFor="category"
                                               className="text-lg text-black font-bold mb-2">문의내용</label>
                                    </div>
                                    <div className="flex flex-row justify-start items-start w-4/5">
                    <textarea id="company"
                              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-full h-96 mt-1"
                              placeholder="문의내용문을 입력해 주세요."/>
                                    </div>
                                </div>

                                <div className="mt-6 mr-32 flex justify-center ">


                                    <div className="mt-24 flex flex-col justify-center items-start w-full">
                                        <div className="flex flex-col justify-start items-start ">
                                            <label htmlFor="category" className="mt-5 text-lg text-black font-bold mb-2">개인정보
                                                활용동의</label>
                                        </div>
                                        <h3 className={"text-gray-400 "}>회사는 수집한 개인정보를 다음의 목적으로 보유하고 활용합니다.</h3>
                                        <div
                                            className="pl-3 p-8 mt-5 h-auto box flex flex-row justify-start items-center  bg-gray-100">
                                            <ul className={"ml-8 font-light text-gray-400"}>
                                                <li> ① 수집/이용 목적 : 고객문의 응대 및 상담</li>
                                                <li>② 수집/이용 항목 : 업체명, 문의자, 이메일, 전화번호</li>
                                                <li>③ 보유 기간 : 문의처리 완료일부터 1년</li>
                                                <li className={"mt-9"}>※ 본 동의는 당사의 고객문의를 이용하기 위해 필요한 동의로써, 동의하지 않으실 경우
                                                    고객문의 서비스를 이용하실 수
                                                    없습니다.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div className="mt-20 mr-48 flex flex-row justify-end w-4/5">
                                    <input type="checkbox" id="agreePrivacy" className="mr-2"/>
                                    <label htmlFor="agreePrivacy" className="text-xl text-black w-4/5">개인정보 수집 및 이용에
                                        동의합니다.</label>
                                </div>
                                <hr className=" mt-4 w-3/4 border-b border-gray-300 border-0"/>
                                <button
                                    className="mt-24 mb-10 p-3 text-white bg-gray-700 hover:bg-gray-900 w-1/12 h-14 ">문의하기
                                </button>
                                <hr className="mt-24 mb-10 w-full border-b border-gray-300 border-0"/>
                            </div>

                        </div>
                    </form>

                </div>
            </div>
            <Footer/>
        </>


    )
}

export default Contact;