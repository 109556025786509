import 'aos/dist/aos.css';
const IntroCoreValue2 = () => {
    return (
        <>
        <div className="flex w-full  m-auto  ">
            <div className="text-gray-800 font-bold mt-[40px]
                           lg:text-[70px] lg:mx-32 lg:pr-[0px]  lg:leading-snug   lg:w-[150px]
                           md:text-[40px] md:mx-12 md:pr-[10px] md:leading-snug   md:w-[120px]
                           sm:text-[20px] sm:ml-5  sm:pr-[20px] sm:leading-[32px] sm:w-[120px]
            ">
                1990<br/>~2000
            </div>
            <div className="mt-12 flex lg:flex-col w-full justify-items-center">
                <div text-lg text-gray-400 mr-5 data-aos="fade-left ">
                    <ul>
                        <div className={"text-lg text-gray-400 mr-5 lg:float-left "}>1990.03</div>
                        <div className={"text-lg md:text-xl text-black  break-keep"}>도서유통 날개 설립(서울배송)</div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1991.03</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>창고물류업 시작(출판계 최초 - 30평) </div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1993.05</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>마포물류센터(700평) 이전 </div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1995.03</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>수색물류센터 확장(700평) </div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1997.10</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>파주출판물류 주식회사 설립(중소기업진흥공단 협동화 사업) </div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1998.01</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>물류센터 사옥 신축(2800평 파주시 교하) 이전 </div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1998.12</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>(주)날개물류 법인 전환</div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1999.10</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>출판물류업계 최초 화물운수면허 취득(날개물류, 파주출판물류)</div>

                        <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>1999.11</div>
                        <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>황금날개 분사(서울배송물류)/행주내동</div>

                        <li className="mt-24 bottom-0 w-4/6 h-1 bg-gray-300"></li>
                    </ul>
                </div>
            </div>
        </div>
            <div className="flex w-full  m-auto  ">
                <div className="text-gray-800 font-bold mt-[40px]
                           lg:text-[70px] lg:mx-32 lg:pr-[0px]  lg:leading-snug   lg:w-[150px]
                           md:text-[40px] md:mx-12 md:pr-[10px] md:leading-snug   md:w-[120px]
                           sm:text-[20px] sm:ml-5  sm:pr-[20px] sm:leading-[32px] sm:w-[120px]
            ">2000<br/>~2010
                </div>
                <div className="mt-12 flex lg:flex-col w-full justify-items-center">
                    <div text-lg text-gray-400 mr-5 data-aos="fade-left">
                        <ul>
                            <div className={"text-lg text-gray-400 mr-5 lg:float-left "}>2000.01</div>
                            <div className={"text-lg md:text-xl text-black  break-keep"}>(주)드림날개 설립(전국배송)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2002.05</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>물류센터 증축 (2400평/총 5200평)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2002.05</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>(주)드림날개 화물운수 면허 취득</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2003.12</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>(주) 북스빌 설립(중소기업진흥공단 협동화) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2004.05</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>운송주선업 취득(날개물류)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2004.05</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>(주) 북스빌 물류신기술 특허 출원 – 출판물류업계 최초</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2004.06</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>(주) 북스빌 물류신기술 벤처 승인 – 출판물류업계 최초 </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2006.01</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>(주)드림날개 2006 경영혁신형 중소기업 선정 </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2007.06</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>부곡집배송단지 부지매입완료(파주읍 부곡리 : 70,000평) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2008.01</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>출판전문지도(GPS/PDA) 시스템 (차량 관제기능) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2008.12</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>부곡집배송단지 지구단위 개발허가 완료(25,000평) </div>

                            <li className="mt-24 bottom-0 w-4/6 h-1 bg-gray-300"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex w-full  m-auto  ">
                <div className="text-gray-800 font-bold mt-[40px]
                           lg:text-[70px] lg:mx-32 lg:pr-[0px]  lg:leading-snug   lg:w-[150px]
                           md:text-[40px] md:mx-12 md:pr-[10px] md:leading-snug   md:w-[120px]
                           sm:text-[20px] sm:ml-5  sm:pr-[20px] sm:leading-[32px] sm:w-[120px]
            ">2010<br/>~2020
                </div>
                <div className="mt-12 flex lg:flex-col w-full justify-items-center">
                    <div text-lg text-gray-400 mr-5 data-aos="fade-left">
                        <ul>
                            <div className={"text-lg text-gray-400 mr-5 lg:float-left "}>2010.03</div>
                            <div className={"text-lg md:text-xl text-black  break-keep"}>현대식 대형 물류센터 신사옥 확장 이전(9200평), 자동소터기(배송현장) 도입 </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2014.01</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>날개2(파주출판물류 주식회사 상호변경), 창고 신축(1800평) - &gt; 영풍문고 입주 </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2014.07</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>교보문고 제1물류센터 신축 이전완료(단지 내 유치 입주) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2014.08</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>우리들날개 설립(날개 복지법인)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2016.06</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>제주도 연수원 개원</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2017.11</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>“날개차 앱” 출시/서울,수도권 적용(실시간 배송확인)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2017.11</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>출판전문지도(GIS/GPS/PDA) 시스템 (모바일 전환) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2018.12</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>모바일 전자통합인수증 전환</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2019.01</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>주식회사 황금날개플러스 설립</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2019.07</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>날개맨 설립(주선업)</div>

                    <li className="mt-24 bottom-0 w-4/6 h-1 bg-gray-300"></li>
                </ul>
            </div>
        </div>
            </div>
            <div className="flex w-full  m-auto  ">
                <div className="text-gray-800 font-bold mt-[40px]
                           lg:text-[70px] lg:mx-32 lg:pr-[0px]  lg:leading-snug   lg:w-[150px]
                           md:text-[40px] md:mx-12 md:pr-[10px] md:leading-snug   md:w-[120px]
                           sm:text-[20px] sm:ml-5  sm:pr-[20px] sm:leading-[32px] sm:w-[120px]
            ">
                    2020<br/>~{new Date().getFullYear()}
                </div>
                <div className="mt-12 flex lg:flex-col w-full justify-items-center">
                    <div text-lg text-gray-400 mr-5 data-aos="fade-left ">
                        <ul>
                            <div className={"text-lg text-gray-400 mr-5 lg:float-left "}>2020.09</div>
                            <div className={"text-lg md:text-xl text-black  break-keep"}>황금날개플러스 사옥 신축(3000평)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2022.02</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>연수원 개원(논산)</div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2022.12</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>북스파이더 주식회사 설립(IT개발전문) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2023.06</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>날개차앱 전국 지점 배송 적용(실시간 배송확인/GIS) </div>

                            <div className={"text-lg text-gray-400 mr-5 lg:float-left lg:mt-10 sm:mt-5"}>2024.01</div>
                            <div className={"text-lg md:text-xl text-black lg:mt-10 break-keep"}>출판사, 서점, 물류 통합유통 플랫폼 개발 중(2025년 오픈예정) </div>

                            <li className="mt-24 bottom-0 w-4/6 h-1 bg-gray-300"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IntroCoreValue2;
