import React from "react";
import ModalVideo from "react-modal-video";
import "./ModalCss.scss";

function VideoModal({ isOpen, close }) {
    return (
        <div>
                <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId="MpAswzQRMhg"
                    onClose={close}
                />
        </div>
    );
}

export default VideoModal;
