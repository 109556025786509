import React, { useState } from 'react';
import {Statistics1} from "./Statistics1";
import {Statistics2} from "./Statistics2";
import {Statistics4} from "./Statistics4";

const Statistics = () => {
    const [selectedCategory, setSelectedCategory] = useState('line');

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    }

    let selectedComponent;
    if (selectedCategory === 'line') {
        selectedComponent = <Statistics1/>;
    } else if (selectedCategory === 'bar') {
        selectedComponent = <Statistics2/>;
    }

    return (
        <>
            <div className="text-center ">
                <div className='mt-[200px] '>
                <div className="text-left  my-[20px] text-[#333333] font-[700]
                                lg:text-[44px] lg:leading-[30px] lg:mx-[120px]
                                md:text-[36px] md:leading-[30px] md:mx-[80px]
                                sm:text-[28px] sm:leading-[28px] sm:mx-[40px]">10대 주요 서점 월별 출고 현황
                    <span className="text-gray-500
                                     md:text-[18px] md:ml-[15px] md:flex md:mt-[20px]
                                     sm:text-[16px] sm:ml-[10px] sm:flex sm:mt-[20px]">(2022년 이후)</span>
                </div>
                    <button onClick={() => handleCategoryChange('line')}
                            className= {`category-button ${selectedCategory === 'warehouse' ? 'active selected' : ''} mx-[0.7vw] bg-white border-[1px] border-gray-300 transition-all ease-in-out duration-400 text-[#777777} hover:bg-gray-200 hover:text-white rounded-2xl group`}
                            style={{
                                backgroundColor: selectedCategory === 'line' ? '#F19131' : 'white',
                                color: selectedCategory === 'line' ? 'white' : '#777777'
                            }}>
                        <h2 className=" lg:text-[20px] lg:leading-[24px] lg:font-[700] lg:my-[20px] lg:w-[250px]
                                        md:text-[18px] md:leading-[21px] md:font-[700] md:my-[15px] md:w-[200px]
                                        sm:text-[15px] sm:leading-[19px] sm:font-[700] sm:my-[10px] sm:w-[150px]">Spectrum Graph</h2>
                    </button>

                    <button onClick={() => handleCategoryChange('bar')}
                            className= {`category-button ${selectedCategory === 'warehouse' ? 'active selected' : ''} mx-[0.7vw] bg-white border-[1px] border-gray-300 transition-all ease-in-out duration-400 text-[#777777} hover:bg-gray-200 hover:text-white rounded-2xl group`}
                            style={{
                                backgroundColor: selectedCategory === 'bar' ? '#F19131' : 'white',
                                color: selectedCategory === 'bar' ? 'white' : '#777777'
                            }}>
                        <h2 className=" lg:text-[20px] lg:leading-[24px] lg:font-[700] lg:my-[20px] lg:w-[250px]
                                        md:text-[18px] md:leading-[21px] md:font-[700] md:my-[15px] md:w-[200px]
                                        sm:text-[15px] sm:leading-[19px] sm:font-[700] sm:my-[10px] sm:w-[150px]">Stacked graph</h2>
                    </button>
                </div>

            </div>
            <div>
                {selectedComponent}
            </div>
        </>
    );
}

export default Statistics;
