import React from 'react';
import img1 from '../../../images/home/web/group1.svg';
import union1 from '../../../images/logo/Union1.png';
const Home2 = () => {
    return (
        <>
            <img src={union1} alt="bgUnion" className=' pointer-events-none
                                                         lg:h-[300px] lg:absolute lg:mt-[300px]
                                                         md:h-[250px] md:absolute md:mt-[250vh]
                                                         sm:h-[200px] sm:absolute sm:mt-[5vh]
                                                              '/>
            <div className="h-[100vh]
                            lg:min-h-[850px] lg:max-h-[850px]
                            md:min-h-[750px] md:max-h-[600px]
                            sm:min-h-[600px] sm:max-h-[500px] sm:min-w-[320px]">
                    <div data-aos="fade-up" data-aos-delay="50"  className="text-center" >
                        <div className="lg:text-[44px] lg:leading-[58px] lg:font-[700] lg:text-[#333333] lg:pt-[120px] lg:pb-[20px]
                                        md:text-[38px] md:leading-[56px] md:font-[700] md:text-[#333333] md:pt-[80px] md:pb-[20px]
                                        sm:text-[26px] sm:leading-[42px] sm:font-[700] sm:text-[#333333] sm:pt-[80px] sm:pb-[20px]
                                        "> 원클릭! 입고에서 배송까지<br/>
                                           도서 물류 업무를 한 번에 해결</div>
                        <div>
                            <p className='lg:text-[22px] lg:leading-[36px] lg:font-normal lg:text-[#333333] lg:pb-[48px]
                                          md:text-[22px] md:leading-[36px] md:font-normal md:text-[#333333] md:pb-[48px]
                                          sm:text-[12px] sm:leading-[24px] sm:font-normal sm:text-[#333333] sm:pb-[48px]
                                          '>
                                34년 출판 물류 한길만 걸어온 날개만의 최적화된<br/>
                                인프라와 함께 입고 &gt; 보관 &gt; 출고 및 반품 관리 &gt; 전국 배송까지<br/>
                                원스톱 업무 시스템을 제공합니다.</p>
                        </div>
                    </div>
                    <div className="flex justify-center ">
                        <div data-aos="fade-up" data-aos-delay="50" className="flex justify-center  h-auto lg:pb-[95px]">
                            <img className="h-[45wh]
                                            lg:max-h-[400px]
                                            md:max-h-[300px]
                                            sm:max-h-[200px]" alt="card1" src={img1} />
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Home2;
