import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length && label) {
        const dataPoint = payload[0].payload;
        return (
            <div className="bg-white border-[1px] border-[#EDEDED] px-[20px] py-[15px] rounded-[5px] shadow-md text-left ">
                <span className="text-[#F29232] ">•</span>
                <span className="font-[700] text-[#555555] text-[16px]">{`${label}`}</span>
                <div className="border-l-[1px] border-[#DDDDDD] pl-[10px] mt-[6px]">
                    <p className="text-left text-[14px] text-[#555555">{dataPoint.contents1}</p>
                    <p className="text-left text-[14px] text-[#555555">{dataPoint.contents2}</p>
                    <p className="text-left text-[14px] text-[#555555">{dataPoint.contents3}</p>
                </div>
            </div>
        );
    }
    return null;
};

const data = [
    {
        name: '',
        uv: 800,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 800,
        contents1:"",
        contents2:"",
        contents3:""

    },
    {
        name: '1990년',
        uv: 830,
        contents1:"서울배송 시작",
        contents2:"3개 출판사",
        contents3:"1t 차량 1대",
    },
    {
        name: '1991년',
        uv: 860,
        contents1:"창고물류시작",
        contents2:"(출판계 최초)",
        contents3:"30평 창고"
    },
    {
        name: '',
        uv: 950,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '2000년',
        uv: 1100,
        contents1:"지방배송 시작",
        contents2:"",
        contents3:""
    },

    {
        name: '',
        uv: 1400,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 1850,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 2450,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 3150,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 4000,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 5200,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '2024년',
        uv: 6900,
        contents1:"첨단 플랫폼",
        contents2:"개발 중",
        contents3:""
    },
    {
        name: '',
        uv: 9100,
        contents1:"",
        contents2:"",
        contents3:""
    },
    {
        name: '',
        uv: 9100,
        contents1:"",
        contents2:"",
        contents3:""
    },
];
const renderCustomDot = (props) => {
    const { cx, cy, payload } = props;
    if (payload.name !== '') {
        return (
            <g>
                <circle cx={cx} cy={cy} r={7} fill="#F19131" filter="url(#shadow)"/>
                <text x={cx} y={cy - 15} textAnchor="middle" fill="#000000" fontSize={11}>{payload.name}</text>
                <filter id="shadow" x="-50%" y="-50%" width="100" height="100">
                    <feOffset result="offOut" in="SourceAlpha" dx="1" dy="1"/>
                    <feColorMatrix type="matrix" values="0.2 0 0 0 0 0 0.2 0 0 0 0 0 0.2 0 0 0 0 0 1 0"/>
                    <feGaussianBlur stdDeviation="1"/>
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                </filter>
            </g>
        );
    }
    return null;
};


export const Statistics4 = () => {
    return (
        <div className="h-[60vh] w-[118%] -ml-[10vw] sm:-ml-[15vw] sm:h-[50vh]
                        lg:min-h-[800px] lg:max-h-[700px]
                        md:min-h-[700px] md:max-h-[700px]
                        sm:min-h-[500px] sm:max-h-[600px] sm:-ml-[60px]">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    width={1000}
                    height={500}
                    data={data}
                    margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }} >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="20%" stopColor="#F19131" stopOpacity={0.9}/>
                            <stop offset="99%" stopColor="#F19131" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" interval={0} tick={{ fontSize: 14 }} hide="true"  />
                    <YAxis tick={true} domain={[0, 'dataMax']} axisLine={false} />
                    <Tooltip content={<CustomTooltip />}  active={true}  isAnimationActive={false} cursor={false} />
                    <Area type="monotone" dataKey="uv" stroke="#F19131" fill="url(#colorUv)" dot={renderCustomDot} />
                </AreaChart>

            </ResponsiveContainer>
        </div>
    );
};

