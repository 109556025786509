import React, {useState, useEffect, useRef} from 'react';
import aboutTruck from '../../../images/about/aboutTruck.png'


const About2 = () => {

    const [count, setCount] = useState(1);
    const [count2, setCount2] = useState(1);
    const [count3, setCount3] = useState(1);
    const [count4, setCount4] = useState(1);
    const [count5, setCount5] = useState(1);
    const [count6, setCount6] = useState(1);
    const [count7, setCount7] = useState(1);
    const targetRef = useRef(null);
    const targetRef2 = useRef(null);
    const targetRef3 = useRef(null);
    const targetRef4 = useRef(null);
    const targetRef5 = useRef(null);
    const targetRef6 = useRef(null);
    const targetRef7 = useRef(null);
    const [intersected, setIntersected] = useState(false);
    const [intersected2, setIntersected2] = useState(false);
    const [intersected3, setIntersected3] = useState(false);
    const [intersected4, setIntersected4] = useState(false);
    const [intersected5, setIntersected5] = useState(false);
    const [intersected6, setIntersected6] = useState(false);
    const [intersected7, setIntersected7] = useState(false);

    useEffect(() => {
        if (!intersected && targetRef.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected) {
                        setIntersected(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected, targetRef]);

    useEffect(() => {
        if (!intersected2 && targetRef2.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected2) {
                        setIntersected2(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected2]);

    useEffect(() => {
        if (!intersected3 && targetRef3.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected3) {
                        setIntersected3(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef3.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected3]);


    useEffect(() => {
        if (!intersected4 && targetRef4.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected4) {
                        setIntersected4(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef4.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected4]);

    useEffect(() => {
        if (!intersected5 && targetRef5.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected5) {
                        setIntersected5(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef5.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected5]);


    useEffect(() => {
        if (!intersected6 && targetRef6.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected6) {
                        setIntersected6(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef6.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected6]);
    //
    //
    useEffect(() => {
        if (!intersected7 && targetRef7.current) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !intersected7) {
                        setIntersected7(true);
                    }
                });
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            });

            observer.observe(targetRef7.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [intersected7]);

    useEffect(() => {
        if (intersected) {
            const interval = setInterval(() => {
                setCount(prevCount => {
                    if (prevCount < 20000) {
                        return prevCount + 99;
                    } else {
                        clearInterval(interval);
                        return 20000;
                    }
                });
            }, 12);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected]);

    useEffect(() => {
        if (intersected2) {
            const interval = setInterval(() => {
                setCount2(prevCount => {
                    if (prevCount < 45000) {
                        return prevCount + 199;
                    } else {
                        clearInterval(interval);
                        return 45000;
                    }
                });
            }, 11);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected2]);

    useEffect(() => {
        if (intersected3) {
            const interval = setInterval(() => {
                setCount3(prevCount => {
                    if (prevCount < 40) {
                        return prevCount + 1;
                    } else {
                        clearInterval(interval);
                        return 40;
                    }
                });
            }, 30);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected3]);
    //
    useEffect(() => {
        if (intersected4) {
            const interval = setInterval(() => {
                setCount4(prevCount => {
                    if (prevCount < 150) {
                        return prevCount + 1;
                    } else {
                        clearInterval(interval);
                        return 150;
                    }
                });
            }, 17);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected4]);


    useEffect(() => {
        if (intersected5) {
            const interval = setInterval(() => {
                setCount5(prevCount => {
                    if (prevCount < 36000) {
                        return prevCount + 221;
                    } else {
                        clearInterval(interval);
                        return 36000;
                    }
                });
            }, 30);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected5]);

    useEffect(() => {
        if (intersected6) {
            const interval = setInterval(() => {
                setCount6(prevCount => {
                    if (prevCount < 99.9) {
                        return prevCount + 0.2;
                    } else {
                        clearInterval(interval);
                        return 99.9;
                    }
                });
            }, 1);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected6]);

    useEffect(() => {
        if (intersected7) {
            const interval = setInterval(() => {
                setCount7(prevCount => {
                    if (prevCount < 100) {
                        return prevCount + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 25);

            return () => {
                clearInterval(interval);
            };
        }
    }, [intersected7]);


    return (
        <>
            <div className="grid grid-cols-10 mt-24 ml-2
                            sm:mx-5 gap-8
            ">
                <div className="grid grid-cols-8
                                2xl:grid 2xl:col-start-3 2xl:col-end-3
                                xl:grid xl:col-start-2 xl:col-end-3
                                lg:grid lg:col-start-2 lg:col-end-3
                                md:grid md:col-start-2 md:col-end-5
                                sm:grid sm:col-start-2 sm:col-end-5 sm:mb-[48px]
                                ">
                    <div className="col-start-1 col-span-2  font-regular text-lg text-2xl md:text-[1.2rem] sm:text-[0.9rem] min-w-[7rem]" ref={targetRef}>면적
                        <span className="text-gray-500 lg:text-[16px] md:text-[15px] sm:text-[12px]"> (건평)</span>
                    </div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                    md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                    sm:min-w-[20rem]  sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]
                    ">
                        <span className="inline-block
                                                         lg:pr-0.5vw] lg:mr-[65px] lg:w-[100px]
                                                         md:pr-[2vw] md:mr-[75px]  md:w-[60px]
                                                         sm:w-[3vw]  sm:mr-[12px]  sm:w-[50px]
                                                         ">{count.toLocaleString()}</span><span
                        className="col-start-4  col-end-5  font-bold text-blue-900 text-5xl md:text-[2.5rem] sm:text-[1.25rem]">평 </span>
                        <span
                            className="col-start-6 col-end-8   font-bold text-orange-500 text-5xl md:text-[2.5rem] sm:text-[1.25rem] ">이상</span>
                    </div>
                </div>
                <div className="grid grid-cols-8
                                 xl:grid xl:col-start-5 xl:col-end-6
                                 lg:grid lg:col-start-5 lg:col-end-6
                                 md:grid md:col-start-6 md:col-end-9
                                 sm:grid sm:col-start-6 sm:col-end-9 sm:mb-[48px]
                                ">
                    <div className="col-start-1 col-span-2 text-lg text-2xl md:text-[1.2rem] sm:text-[0.9rem]   min-w-[7rem]" ref={targetRef2}>파렛트 보관 수
                    </div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                    md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                     sm:min-w-[20rem]  sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]

                    ">
                         <span className="inline-block
                                                         lg:pr-0.5vw] lg:mr-[65px] lg:w-[100px]
                                                         md:pr-[2vw] md:mr-[75px] md:w-[60px]
                                                         sm:w-[3vw]  sm:mr-[18px]  sm:w-[50px]
                                                         ">{count2.toLocaleString()}</span><span
                        className="col-start-4  col-end-5  font-bold text-blue-900 md:text-[2.5rem] sm:text-[1.25rem]">장 </span>
                        <span
                            className="col-start-6 col-end-8   font-bold text-orange-500 md:text-[2.5rem] sm:text-[1.25rem]">이상</span>
                    </div>
                </div>
                <div className="grid grid-cols-8
                                 2xl:grid 2xl:col-start-7 2xl:col-end-9
                                 xl:grid xl:col-start-8 xl:col-end-9
                                 lg:grid lg:col-start-8 lg:col-end-10 lg:row-start-1 lg:mt-0
                                  md:grid md:col-start-2 md:row-start-4 md:col-end-5 md:mt-10
                                  sm:grid sm:col-start-2 sm:row-start-4 sm:col-end-5
                ">
                    <div className="col-start-1 col-span-2   text-lg text-2xl md:text-[1.2rem] sm:text-[0.9rem] min-w-[5rem]" ref={targetRef3}>지게차</div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                     md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                    sm:min-w-[20rem]   sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]


                    ">
                       <span className="inline-block
                                                         lg:pr-0.5vw] lg:mr-[1px] lg:w-[60px]
                                                         md:pr-[2vw] md:mr-[10px] md:w-[60px]
                                                         sm:w-[3vw]  sm:mr-[16px]  sm:w-[20px]
                                                         ">{count3.toLocaleString()}</span><span
                        className="col-start-4  col-end-5  font-bold text-blue-900 md:text-[2.5rem] sm:text-[1.25rem]">대 </span>
                        <span
                            className="col-start-6 col-end-8   font-bold text-orange-500 md:text-[2.5rem] sm:text-[1.25rem]">이상</span>
                    </div>
                </div>
                <div className="grid grid-cols-8
                                 2xl:grid 2xl:col-start-3 2xl:col-end-3
                                xl:grid xl:col-start-2 xl:col-end-4 xl:mt-20
                                lg:grid lg:col-start-2 lg:col-end-3 lg:mt-20
                                md:grid md:col-start-2 md:col-end-5 md:mt-10
                                sm:grid sm:col-start-2 sm:col-end-5 sm:mb-[48px]
                                ">
                    <div className="col-start-1 col-span-2   text-lg text-2xl md:text-[1.2rem] sm:text-[0.9rem] min-w-[10rem]" ref={targetRef4}>일일 최대 소화량
                    </div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                    md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                    sm:min-w-[20rem]  sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]

                    ">
                        <span className="inline-block
                                         lg:mr-[20px] lg:w-[70px]
                                         md:mr-[15px] md:w-[60px]
                                         sm:mr-[20px] sm:w-[3vw]
                                                         ">{count4.toLocaleString()}</span><span
                        className="col-start-4  col-end-5  font-bold text-blue-900 md:text-[2.5rem] sm:text-[1.25rem]">만권 </span>
                        <span
                            className="col-start-6 col-end-8   font-bold text-orange-500 md:text-[2.5rem] sm:text-[1.25rem]">이상</span>
                    </div>
                </div>
                <div className="grid grid-cols-8
                                 xl:grid xl:col-start-5 xl:col-end-6 xl:mt-20
                                 lg:grid lg:col-start-5 lg:col-end-6 lg:mt-20
                                 md:grid md:col-start-6 md:col-end-9 md:mt-10
                                 sm:grid sm:col-start-6 sm:col-end-9 sm:mb-[48px]
                                ">
                    <div className="col-start-1 col-span-2  text-lg  md:text-[1.2rem] sm:text-[0.9rem] min-w-[10rem]" ref={targetRef5}>연간 최대 소화량
                    </div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                    md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                    sm:min-w-[20rem]  sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]

                    ">
                        <span className=" lg:mr-[5px] lg:w-[40px]
                                          md:mr-[5px] md:w-[60px]
                                          sm:mr-[0px] sm:w-[50px]
                                         ">3억6천</span>
                        <span className=" font-bold text-blue-900 md:text-[2.5rem] sm:text-[1.25rem]">만부
                            <span className="w-[330px]  font-bold text-orange-500 md:text-[2.5rem] sm:text-[1.25rem]"> 이상</span>
                        </span>
                    </div>
                </div>

                <div className="grid grid-cols-8
                                2xl:grid 2xl:col-start-3 2xl:col-end-3
                                xl:grid xl:col-start-2 xl:col-end-4 xl:mt-20
                                lg:grid lg:col-start-2 lg:col-end-3 lg:mt-20
                                md:grid md:col-start-2 md:col-end-5 md:mt-10
                                sm:grid sm:col-start-2 sm:col-end-5 sm:mb-[48px]
                                ">
                    <div className="col-start-1 col-span-2   text-lg text-2xl md:text-[1.2rem] sm:text-[0.9rem] min-w-[10rem]" ref={targetRef6}>출고 정확도
                    </div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                    md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                    sm:min-w-[20rem]  sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]

                    ">
                        <span className="inline-block
                                                         lg:pr-0.5vw] lg:mr-[5px] lg:w-[90px]
                                                         md:pr-[2vw] md:mr-[10px] md:w-[60px]
                                                         sm:w-[3vw]  sm:mr-[27px]  sm:w-[30px]
                                                         ">{count6.toLocaleString()}</span> <span
                        className="col-start-4  col-end-5  font-bold text-blue-900 md:text-[2.5rem] sm:text-[1.25rem]"></span>
                        <span
                            className="col-start-6 col-end-8   font-bold text-orange-500 md:text-[2.5rem] sm:text-[1.25rem]">%</span>
                    </div>
                </div>
                <div className="grid grid-cols-8
                               xl:grid xl:col-start-5 xl:col-end-6 xl:mt-20
                                 lg:grid lg:col-start-5 lg:col-end-6 lg:mt-20
                                 md:grid md:col-start-6 md:col-end-9 md:mt-10
                                 sm:grid sm:col-start-6 sm:col-end-9 sm:mb-[48px]
                                ">
                    <div className="col-start-1 col-span-2   text-lg text-2xl md:text-[1.2rem] sm:text-[0.9rem] min-w-[10rem]" ref={targetRef7}>화물 차량
                    </div>
                    <div className=" font-bold text-blue-900
                                    2xl:min-w-[20rem] 2xl:col-start-1  2xl:col-span-8 2xl:text-5xl
                                    xl:min-w-[20rem]  xl:col-start-1  xl:col-span-8 xl:text-5xl
                                    lg:min-w-[20rem]  lg:col-start-1  lg:col-span-8 lg:text-5xl
                                    md:min-w-[20rem]  md:col-start-1  md:col-span-8 md:text-5xl md:text-[2.5rem]
                                    sm:min-w-[20rem]  sm:col-start-1  sm:col-span-8 sm:text-[1.25rem]

                    ">
                        {count7.toLocaleString()} <span
                        className="col-start-4  col-end-5  font-bold text-blue-900 md:text-[2.5rem] sm:text-[1.25rem]"></span>
                        <span
                            className="col-start-6 col-end-8   font-bold text-orange-500 text-5xl md:text-[2.5rem] sm:text-[1.25rem] ">여대</span>
                    </div>
                </div>
                <div className="grid grid-cols-8
                               xl:grid xl:col-start-5 xl:col-end-6 xl:-mt-20 xl:ml-60
                               lg:grid lg:col-start-8 lg:col-end-10 lg:row-start-4 lg:-mt-10
                               md:grid md:col-start-5 md:row-start-5 md:col-end-5 md:-ml-2 md:-mt-10
                               sm:grid sm:col-start-6 sm:row-start-5 sm:col-end-5 sm:-ml-10 sm:-mt-10
                               ">
                    <img className="min-w-[60vm] max-w-[60vw] sm:ml-[50px] overflow-hidden" src={aboutTruck} alt="background image"
                         style={{transform: '', opacity: 0.8}}/>
                </div>
            </div>

        </>
    );
}

export default About2;
