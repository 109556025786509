import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; // React Router의 useLocation 훅 사용

function Notice3() {
    const location = useLocation(); // useLocation 훅으로 location 객체 가져오기
    const { noticeData } = location.state; // Notice2에서 전달된 데이터 가져오기
    const [imageFile, setImageFile] = useState(null); //이미지파일 state

    //이미지 파일을 state로 저장
    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };

    return (
        <div className="flex flex-col items-center mt-24">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <div className="p-4">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">제목: {noticeData.title}</h2>
                    <p className="text-gray-600 text-sm mb-4">날짜: {noticeData.date}</p>
                    <textarea className="text-gray-900 border-2 min-h-[40rem] min-w-full mb-4"
                              value={noticeData.body}
                              readOnly
                    />
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                    {imageFile && (
                        <img className="mt-4 max-w-[500px]"  src={URL.createObjectURL(imageFile)} alt="UploadedImage"/> //바뀐 imageFile의 state로 url생성하여 이미지 미리보기
                    )}
                </div>
            </div>

        </div>
    );
}

export default Notice3;
