/*
라우터를 사용할 때 페이지가 변경될 때마다 페이지의 맨 위로 스크롤을 자동으로 이동시키는 컴포넌트
 */

import { useEffect, ReactNode } from "react";
// import { useLocation } from "react-router-dom";

type ScrollToTopProps = {
    children: ReactNode;
};

const ScrollToTop = ({ children }: ScrollToTopProps) => {
    // const location = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return <>{children}</>;
};

export default ScrollToTop;
