import React, {useEffect} from 'react';
import vision0 from '../../../images/vision/vision0.webp';

const Vision1 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show');
                } else {
                    entry.target.classList.remove('show');
                }
            });
        });
        const hiddenElemnts = document.querySelectorAll('#hidden');
        hiddenElemnts.forEach((elements) => observer.observe(elements));

    }, []);


    return (

        <div className=" m-auto  overflow-x-hidden" >
            <h2 className="text-[#242429] font-[600] font-bold
                                   lg:text-[64px] lg:mt-[200px] lg:ml-[100px]
                                   md:text-[56px] md:mt-[150px] md:ml-[70px]
                                   sm:text-[48px] sm:mt-[100px] sm:ml-[40px] ">
                사명감</h2>
            <div className="flex lg:flex-row lg:text-left justify-end" data-aos="fade-up">
                <div className="text-left  lg:justify-end w-full lg:w-3/4
                                 lg:ml-[0px]
                                 md:ml-[100px]
                                 sm:ml-[80px]">
                    <h4 className="font-light mb-12 break-keep
                                    lg:text-[22px] lg:leading-[40px] lg:mt-[60px]
                                    md:text-[20px] md:leading-[36px] md:mt-[50px]
                                    sm:text-[16px] sm:leading-[28px] sm:mt-[40px]  sm:mr-[28px]
                   ">날개 물류는 1990년 출판계 최초 창고관리 대행을 시작으로 출판계의 물류 유통에 없어서는 안 될 핵심으로 성장했습니다. <br/> 날개는 사적인 기업을 넘어 출판계의 미래를 위해 공익적 사명을 다할 것입니다.
                    </h4>
                    <div className="lg:my-10 w-full" data-aos="zoom-in" data-aos-delay="50">
                        <img className="" src={vision0} alt="truckparkinground"/>
                    </div>
                </div>
            </div>
        </div>
)
    ;
}

export default Vision1;
