import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const NalgaeTv = () => {
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("https://www.youtube.com/watch?v=MpAswzQRMhg");
    const [selectedVideoTitle, setSelectedVideoTitle] = useState("날개그룹 홍보영상");

    useEffect(() => {
        window.scrollTo(0, 0);
    });
    const handleVideoSelect = (url, title) => {
        setSelectedVideoUrl(url);
        setSelectedVideoTitle(title);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        ReactPlayer.url = selectedVideoUrl;
    }, [selectedVideoUrl]);

    return (
        <>
            <div className=" lg:text-[64px] lg:mt-[200px] lg:ml-[120px]
                             md:text-[56px] md:mt-[150px] md:ml-[80px]
                             sm:text-[48px] sm:mt-[100px] sm:ml-[40px]">
                <div className='font-bold text-[#888888]
                            lg:text-[18px] lg:leading-[21px]
                            md:text-[18px] md:leading-[21px]
                            sm:text-[12px] md:leading-[20px] '> 미디어 센터
                </div>
                <div className='font-bold mt-[16px] mb-[80px]
                            lg:text-[50px] lg:leading-[62px]
                            md:text-[44px] md:leading-[56px]
                            sm:text-[28px] md:leading-[48px] '> 날개 TV
                </div>
            </div>
            <div className="flex justify-center items-center" >
                <style>
                    {`
                        .video-container {
                            position: relative;
                            padding-bottom: 56.25%;
                            height: 0;
                            border-radius: 10px; 
                            overflow: hidden; 
                        }
                        .video-container .video {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;  
                        }
                    `}
                </style>
                <div>
                    <div className="lg:max-w-[1000px] lg:min-w-[1000px]
                                    md:max-w-[700px]  md:min-w-[700px]
                                    sm:max-w-[300px]  sm:min-w-[310px]
                                    ">
                        <div className="font-[600] text-[18px] my-[10px] text-[#333333] ">{selectedVideoTitle}</div>
                        <div className="video-container ">
                            <div className="video">
                                <ReactPlayer className="react-player" width="100%" height="100%" light volume="0.5"
                                             controls playing={true} playsinline={false} url={selectedVideoUrl}/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[20px] lg:max-w-[1200px] lg:min-w-[1000px] mb-[500px]">
                        <div className="mt-[60px] ">
                            <div
                                className='font-bold text-[#888888] mb-[10px] lg:text-[18px] lg:leading-[21px] md:text-[18px] md:leading-[21px] sm:text-[14px] md:leading-[20px] '> 최신순(N)
                            </div>
                        </div>
                        <div className=" grid lg:grid-cols-2 lg:gap-[30px] md:grid-cols-2 md:gap-[30px] sm:grid-rows-1">
                            <div className="text-justify pb-[50px]"
                                 onClick={() => handleVideoSelect("https://www.youtube.com/watch?v=l_9EfsAt0Rw", "행복한 만남! 황금날개플러스 - 도서 물류 소개")}>
                                <div className="grid sm:grid-cols-2 cursor-pointer">
                                    <div className="video-container pointer-events-none cursor-pointer">
                                        <div width="250" className="video">
                                            {/* 목록 1 */}
                                            <ReactPlayer className="react-player" width="100%" height="100%" light
                                                         volume="0.5" controls playing={true} playsinline={false}
                                                         url="https://www.youtube.com/watch?v=l_9EfsAt0Rw"/>
                                        </div>
                                    </div>
                                    <div className="sm:ml-[10px]">
                                        <div
                                            className="font-bold h-auto text-[#333333] lg:text-[28px] lg:leading-[52px] lg:font-[700] md:text-[20px] md:leading-[48px] md:font-[700] sm:text-[14px] sm:leading-[42px] sm:font-[700] "> 행복한
                                            만남! 황금날개플러스
                                        </div>
                                        <div
                                            className="font-bold h-auto text-[#868DAF] lg:text-[20px] lg:leading-[20px] lg:font-[700] md:text-[12px] md:leading-[16px] md:font-[700] sm:text-[10px] sm:leading-[12px] sm:font-[700] sm:block"> 2024.04.29
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-justify pb-[50px]"
                                 onClick={() => handleVideoSelect("https://www.youtube.com/watch?v=ksW8oezR1kQ", "날개물류 산업 안전 교육")}>
                                <div className="grid sm:grid-cols-2 cursor-pointer">
                                    <div className="video-container pointer-events-none cursor-pointer">
                                        <div width="250" className="video">
                                            {/* 목록 2 */}
                                            <ReactPlayer className="react-player" width="100%" height="100%" light
                                                         volume="0.5" controls playing={true} playsinline={false}
                                                         url="https://www.youtube.com/watch?v=ksW8oezR1kQ"/>
                                        </div>
                                    </div>
                                    <div className="sm:ml-[10px]">
                                        <div
                                            className="font-bold h-auto text-[#333333] lg:text-[28px] lg:leading-[52px] lg:font-[700] md:text-[20px] md:leading-[48px] md:font-[700] sm:text-[14px] sm:leading-[42px] sm:font-[700] "> 날개물류
                                            산업 안전 교육
                                        </div>
                                        <div
                                            className="font-bold h-auto text-[#868DAF] lg:text-[20px] lg:leading-[20px] lg:font-[700] md:text-[12px] md:leading-[16px] md:font-[700] sm:text-[10px] sm:leading-[12px] sm:font-[700] "> 2024.04.29
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" grid lg:grid-cols-2 lg:gap-[30px] md:grid-cols-2 md:gap-[30px] sm:grid-rows-1">
                            <div className="text-justify pb-[50px]"
                                 onClick={() => handleVideoSelect("https://www.youtube.com/watch?v=MpAswzQRMhg", "날개그룹 홍보영상")}>
                                <div className="grid sm:grid-cols-2 cursor-pointer">
                                    <div className="video-container pointer-events-none cursor-pointer">
                                        <div width="250" className="video">
                                            {/* 목록 3 */}
                                            <ReactPlayer className="react-player" width="100%" height="100%" light
                                                         volume="0.5" controls playing={true} playsinline={false}
                                                         url="https://www.youtube.com/watch?v=MpAswzQRMhg"/>
                                        </div>
                                    </div>
                                    <div className="sm:ml-[10px]">
                                        <div
                                            className="font-bold h-auto text-[#333333] lg:text-[28px] lg:leading-[52px] lg:font-[700] md:text-[20px] md:leading-[48px] md:font-[700] sm:text-[14px] sm:leading-[42px] sm:font-[700] "> 날개그룹 홍보영상
                                        </div>
                                        <div
                                            className="font-bold h-auto text-[#868DAF] lg:text-[20px] lg:leading-[20px] lg:font-[700] md:text-[12px] md:leading-[16px] md:font-[700] sm:text-[10px] sm:leading-[12px] sm:font-[700] sm:block"> 2024.06.05
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-justify pb-[50px]"
                                 onClick={() => handleVideoSelect("", "")}>
                                <div className="grid sm:grid-cols-2 cursor-pointer">
                                    <div className="video-container pointer-events-none cursor-pointer">
                                        <div width="250" className="video">
                                            {/* 목록 4 */}
                                            <ReactPlayer className="react-player" width="100%" height="100%" light
                                                         volume="0.5" controls playing={true} playsinline={false}
                                                         url=""/>
                                        </div>
                                    </div>
                                    <div className="sm:ml-[10px]">
                                        <div
                                            className="font-bold h-auto text-[#333333] lg:text-[28px] lg:leading-[52px] lg:font-[700] md:text-[20px] md:leading-[48px] md:font-[700] sm:text-[14px] sm:leading-[42px] sm:font-[700] ">

                                        </div>
                                        <div
                                            className="font-bold h-auto text-[#868DAF] lg:text-[20px] lg:leading-[20px] lg:font-[700] md:text-[12px] md:leading-[16px] md:font-[700] sm:text-[10px] sm:leading-[12px] sm:font-[700] ">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NalgaeTv;
