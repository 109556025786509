import about1 from '../../../images/about/about1.jpg';
import about2 from'../../../images/about/about2.jpg'
import about3 from '../../../images/about/about3.jpg'
import about4 from '../../../images/about/about4.jpg'
import 우리들날개 from '../../../images/about/우리들날개.jpg'
import 북스파이더 from '../../../images/about/북스파이더.jpg'
import 날개2 from '../../../images/about/날개2.jpg'
import 북스빌 from '../../../images/about/북스빌.webp'

import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const About3 = () => {
    const elementRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.from(elementRef.current, {
            opacity: 0,
            y: 50,
            duration: 1,
            scrollTrigger: {
                trigger: elementRef.current,
                start: "top 80%",
                end: "bottom 20%",
                toggleActions: "play none none reverse",
            }
        });


        gsap.from(elementRef.current, {
            opacity: 1,
            y: 0,
            duration: 1,
            scrollTrigger: {
                trigger: elementRef.current,
                start: "bottom 80%",
                end: "top 20%",
                toggleActions: "play none none reverse",
            }
        });
    }, []);

    return (
        <>
            <div className="md:p-12 h-1/2 mb-[300px]">
                <div className="my-20 mb-5  leading-relaxed  text-[#242429] font-bold
                               lg:text-[56px] lg:ml-[50px]
                               md:text-[50px] md:ml-[30px]
                               sm:text-[40px] sm:ml-[30px] ">
                    그룹사
                </div>
                <div className="flex lg:flex-row justify-center sm:justify-start sm:mt-5" data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 sm:my-0w-full flex justify-center" data-aos="zoom-in"
                             data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright md:w-[700px] lg:w-1/2 lg:min-w-[860px]  object-cover h-80  object-cover"
                                src={about1} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>날개물류</li>
                                    <li className={"text-lg w-auto break-keep lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>도서
                                        물류 전문 업체, 출고/반품/창고관리 및 IT 정보화의 재고관리
                                    </li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>
                                        34년 전통의 노하우! 오직 출판물류 한길만을 걸어온 명실상부 대한민국 도서 물류 1등 기업으로써 국내 최다 회원사를 파트너로 한 IT 정보 기반의 최고 수준의 보관설비 및 첨단 재고관리시스템 구축을 통한 고객 만족! 최상의 호텔 서비스를 제공해 드립니다.
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px]"}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-3 mt-[12px]"}>대표번호 :<span
                                        className={"text-black text-lg sm:text-[14px] ml-3 sm:ml-[5px]"}>031. 940. 1300</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright md:w-[700px] lg:w-1/2 lg:min-w-[860px] object-cover h-80 lg:object-center-centerup"
                                src={about2} alt="background"/>

                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>드림날개</li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>운수사업면허
                                        보유, 본사 관리 체제 전국 배송 직영체제/도서 화물 운송
                                    </li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep "}>차원이
                                        다른 안전하고 신속한 전국 배송의 서비스를 제공하기 위한 국내 최초 실시간 확인 GPS 운행 추적 및 날개차앱을 도입하여 전국 어디든지
                                        드림 날개의 운송 서비스는 고객의 가장 확실한 눈과 발이 되어 최고의 기업 신뢰도를 지켜드립니다.
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px] "}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-3 mt-[12px]"}>대표번호 :<span
                                        className={"text-black text-lg sm:text-[14px] ml-3 sm:ml-[5px]"}>031. 945. 6001</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright md:w-[700px] lg:w-1/2 lg:min-w-[860px] object-cover h-[300px] lg:object-center-centerup"
                                src={about3} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>황금날개</li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>서울
                                        전역 배송 전문 대행/일선 서점/학원 배송 최대 배송망 구축
                                    </li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>출판계
                                        최고의 배본 노하우를 물론 최다 차량 및 첨단 운송 시스템을 보유한 서울 배송 전문 업체로써 서울 전역과 일산, 파주 등 신속하고 정확한 배송이
                                        가능한 최적의 입지 조건을 갖추었으며 상하차 전 과정의 완벽한 검수 시스템을 통해 고객의 가장 믿음직한 운송 파트너가 되겠습니다.
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px] "}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-3 mt-[12px]"}>대표번호 :<span
                                        className={"text-black text-lg sm:text-[14px] ml-3 sm:ml-[5px]"}>031. 972. 4393</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright lg:w-1/2 lg:min-w-[860px] md:w-[700px] object-cover h-80 lg:object-center-centerup"
                                src={about4} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>황금날개 플러스</li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>도서
                                        물류 전문 업체, 스타트 기업 전문 컨설팅/출고/창고관리의 정보화 시스템
                                    </li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>
                                        날개의 도서 물류 역사와 전통을 계승하는 전문가적 매니지먼트 물류 서비스 통해 가장 안전하고 신뢰성 높은 방법으로 고객의 출판 사업을 성공적으로
                                        영위해 나갈 수 있도록 첨단의 물류 혜택을 제공해 드립니다
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px] "}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-3 mt-[12px]"}>대표번호 :<span
                                        className={"text-black text-lg sm:text-[14px] ml-3 sm:ml-[5px]"}>031. 940. 1473</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright lg:w-1/2 lg:min-w-[860px] md:w-[700px] object-cover h-80 lg:object-center-centerup"
                                src={우리들날개} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>우리들
                                        날개
                                    </li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>날개 복지 법인. 기업 복지 시설 관리 및 날개 행복 프로젝트 사업
                                    </li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>
                                        우리들의 날개가 꿈꾸는 세상은 모두가 행복! 모두를 위한 최고의 기업 복지 문화를 창조해 나갑니다. 날개 임직원을 위한 복지 법인 우리들의 날개는 날개의 임직원들이 운영해 나가는 가장 이상적인 기업 문화입니다.
                                    </li>
                                </ul>
                            </div>
                            <div className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px] "}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright lg:w-1/2 lg:min-w-[860px] md:w-[700px] object-cover h-80 lg:object-center-centerup"
                                src={북스파이더} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>북스파이더</li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>
                                        출판 IT 개발 전문</li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>
                                        출판계가 다 같이 함께라는 슬로건으로 출판사, 서점, 물류 회사, 출판물 관련 연관 회사가 원클릭, 원스톱 시행을 향한 첨단 IT 개발 회사로
                                        국내 산업 중 최고의 출판 IT 산업을 꿈꾸고 세계적 IT 대표 모델로 성장함에 있습니다.
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px] sm:pb-[100px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px]"}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright lg:w-1/2 lg:min-w-[860px] md:w-[700px] object-cover h-80 lg:object-center-centerup"
                                src={북스빌} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>북스빌</li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>현대식 물류센터, 시설 안전 관리 및 기업 보안/경비 시스템 지원</li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>
                                        파주를 빛낼 제2의 출판 단지! 그 중심에는 바로 북스빌이 있습니다.
                                        날개의 물류 시스템의 노하우가 집약된 국내 최대 규모의 15,000평의 물류 신사옥을 중심으로
                                        대한민국의 도서출판문화를 선도해 나갈 복합 출판 물류 단지 조성의 꿈과 희망을 북스빌이 만들어 나갑니다.
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px] sm:pb-[100px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px]"}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="flex lg:flex-row justify-center sm:justify-start lg:mt-[100px] md:mt-[80px] sm:mt-[80px]"
                    data-aos="fade-up">
                    <div className="flex-col 1 lg:text-left lg:my-0 lg:justify-center w-full">
                        <div className="lg:my-10 w-full flex justify-center " data-aos="zoom-in" data-aos-delay="50">
                            <img
                                className="rounded-3xl sm:rounded-none sm:w-[390px] sm:h-[230px] sm:object-right-halfright lg:w-1/2 lg:min-w-[860px] md:w-[700px] object-cover h-80 lg:object-center-centerup"
                                src={날개2} alt="background"/>
                        </div>
                        <div className="w-full lg:ml-[0px] md:ml-[12px] sm:ml-[24px]
                                        lg:flex lg:flex-row lg:justify-center lg:gap-40
                                        sm:flex  sm:flex-col">
                            <div className="lg:w-1/5 text-left sm:w-full sm:justify-start sm:text-left sm:pt-[24px]">
                                <ul>
                                    <li className={"text-3xl lg:mt-[0px] md:mt-[20px] md:mb-[20px] sm:text-[28px] font-bold"}>날개2</li>
                                    <li className={"text-lg w-auto break-keep  lg:mt-[20px] sm:mt-[24px] sm:text-[14px] text-[#868DAF]"}>현대식 물류센터(영풍문고 입주)</li>
                                    <li className={"text-lg mt-5 sm:mt-2 sm:text-[16px] md:w-[700px] sm:w-[90vw] break-keep"}>
                                        북스빌과 함께 대한민국의 도서출판문화를 선도해 나갈 복합 출판 물류 단지 조성의 꿈과 희망을 날개2가 함께합니다.
                                    </li>
                                </ul>
                            </div>
                            <div
                                className="lg:w-1/5 text-left sm:w-full  sm:justify-start sm:text-left sm:pt-[25px] sm:pb-[100px]">
                                <ul className={"sm:flex sm:flex-col lg:flex lg:flex-col w-[400px]"}>
                                    <li className={"text-lg sm:text-[14px] text-[#888888] mr-5 mt-[12px]"}>소재지 :
                                        <span className={"text-black text-lg sm:text-[14px] ml-[5px]"}>경기도 파주시 파주읍 윗가마울길 77</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default About3;
