import React, {useEffect} from 'react';
import about0 from '../../../images/about/about0.png';

const About1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (

        <div className="m-auto" >
                    <h2 className="text-[#242429] font-[600] font-bold
                                   lg:text-[64px] lg:mt-[200px] lg:ml-[100px]
                                   md:text-[56px] md:mt-[150px] md:ml-[70px]
                                   sm:text-[48px] sm:mt-[100px] sm:ml-[40px] ">
                        회사 소개</h2>
            <div className="flex lg:flex-row lg:text-left justify-end" data-aos="fade-up">
                <div className="text-left  lg:justify-end w-full lg:w-3/4
                                 lg:ml-[0px]
                                 md:ml-[100px]
                                 sm:ml-[80px]">
                    <h4 className="font-light mb-12 break-keep
                    lg:text-[22px] lg:leading-[40px] lg:mt-[60px]
                    md:text-[20px] md:leading-[36px] md:mt-[50px]
                    sm:text-[16px] sm:leading-[28px] sm:mt-[40px]  sm:mr-[28px]
                   " >
                        날개 그룹은 1990년 도서 유통 날개 상호로 서울 배송을 시작으로 창고관리 대행 (출판물류 최초), 지방 배송까지 원스톱 시스템 완성으로 누구도 따라올 수 없는 대한민국 최고의 출판 물류 인프라를 구축하여 최적의 시스템과 서비스를 제공하기 위해 항상 노력하고 있습니다.
                    </h4>
                    <div className="lg:my-10 w-full" data-aos="zoom-in" data-aos-delay="50">
                        <img className={"w-full h-full"} src={about0} alt="background image" style={{ transform: '', opacity: 0.8 }} />
                    </div>
                </div>
            </div>

        </div>

    );
}

export default About1;
