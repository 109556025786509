import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {FaAngleRight} from "react-icons/fa";
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
const Notice2 = () => {
    const [noticeData, setNoticeData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(10); // 페이지당 포스트 수
    const [expandedNotice, setExpandedNotice] = useState(null); // 클릭하여 확장된 공지사항의 인덱스


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    "https://raw.githubusercontent.com/bookspider/nghp_content/main/content/announcement.json"
                );
                setNoticeData(response.data);
            } catch (error) {
                console.error("Error fetching market share data:", error);
            }
        };
        fetchData();
    }, []);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = noticeData.slice(indexOfFirstPost, indexOfLastPost);
    const navigate = useNavigate(); // React Router의 useNavigate 훅 사용하여 navigate 함수 가져오기
    // 페이지 이동 함수
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        setExpandedNotice(null); // 페이지 이동 시 확장된 공지사항 초기화
    };

    // 이전 페이지로 이동하는 함수
    const goToPrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setExpandedNotice(null); // 페이지 이동 시 확장된 공지사항 초기화
        }
    };

    // 첫 페이지로 이동하는 함수
    const goToFirstPage = () => {
        setCurrentPage(1);
        setExpandedNotice(null); // 페이지 이동 시 확장된 공지사항 초기화
    };

    const goToNextPage = () => {
        if (currentPage < indexOfLastPost) {
            setCurrentPage(currentPage + 1);
            setExpandedNotice(null); // 페이지 이동 시 확장된 공지사항 초기화
        }
    };

    const goToFinalPage = () => {
        const totalPages = Math.ceil(noticeData.length / postsPerPage);
        setCurrentPage(totalPages);
        setExpandedNotice(null); // 페이지 이동 시 확장된 공지사항 초기화
    }
    const handleTitleClick = (index) => {
        setExpandedNotice(expandedNotice === index ? null : index);
    };

    const handleNoticeClick = (data) => {
        navigate('/notice3', { state: { noticeData: data } });
    };

    return (

        <div className="flex flex-col mt-5 min-h-[700px]
                        2xl:mx-[32rem] 2xl:w-6/12
                        xl:mx-[18rem]  xl:w-8/12
                        lg:mx-[10rem]  lg:w-8/12
                        md:mx-[10rem] md:w-4/5
                        sm:mx-[2rem] sm:w-10/12
        ">
            <div className="mb-2 relative border-">
                <li className="w-full h-[0.1rem] bg-black list-none"></li>
            </div>
            <div>
                {currentPosts.map((data, index) => (<div className="mb-2 flex items-center border-b-2" key={data.id}>
                        <h2 className={"mr-10 text-xl select-none"}>
                            {data.id}
                        </h2>
                        <div className="w-full">
                            <div className="inline-block mr-5 text-[1.125rem] hover:cursor-pointer select-none mb-[18px]"onClick={() => handleNoticeClick(data)} > {/*onClick={() => handleTitleClick(index)}*/}
                                {data.title}<br/><li className={"text-[0.875rem] mt-2 text-[#666666] list-none"}>{data.date}</li>
                            </div>
                            <div className="float-right text-[1.125rem] font-semibold cursor-pointer mt-5" style={{ transition: "transform 0.3s ease", transform: expandedNotice === index ? "rotate(90deg)" : "none" }} onClick={() => handleTitleClick(index)}>
                                <FaAngleRight className="icon-arrow" />
                            </div>
                            {expandedNotice === index && (
                                <p className="text-[1rem] mb-[16px] font-lightbold" data-aos={'fade-in'}>{data.body}</p>
                            )}
                        </div>
                    </div>

                ))}
                <div className="pagination text-black flex justify-center mt-5 mb-12">
                    <button className={"mr-4 text-[#393847] opacity-30"} onClick={goToFirstPage}><FontAwesomeIcon icon={faAngleDoubleLeft}/>
                    </button>
                    <button className={"mr-4 text-[#242429] opacity-30"} onClick={goToPrevPage}><FontAwesomeIcon icon={faAngleLeft}/></button>
                    {[...Array(Math.ceil(noticeData.length / postsPerPage)).keys()].map((number) => (
                        <button
                            key={number}
                            onClick={() => paginate(number + 1)}
                            className={currentPage === number + 1 ? "font-bold mr-4 text-[#F19131] border border-orange-400 rounded-[5px] w-[25px]" : "mr-4"}
                        >
                            {number + 1}
                        </button>
                    ))}
                    <button className={"mr-4 text-[#242429] opacity-50"} onClick={goToNextPage}><FontAwesomeIcon icon={faAngleRight}/></button>
                    <button className={"text-[#393847] opacity-50"} onClick={goToFinalPage}><FontAwesomeIcon icon={faAngleDoubleRight}/></button>
                </div>
            </div>

        </div>
    );
};

export default Notice2;
