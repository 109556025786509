import React, { useState } from 'react';
import bt1 from '../../../images/home/web/bt1.svg';
import bt2 from '../../../images/home/web/bt2.svg';

function PlayButton({ click, isHovered }) {
    const [imageSrc, setImageSrc] = useState(bt1);

    return (
        <button
            onClick={click}
            onMouseEnter={() => setImageSrc(bt2)}
            onMouseLeave={() => setImageSrc(bt1)}
            className="play-button"
        >
            <img src={isHovered ? bt2 : imageSrc} alt="Play Button" className="lg:w-[100px] md:w-[85px] sm:w-[60px]" />
        </button>
    );
}

export default PlayButton;
